/**
 * App Template
 * ================
 *
 * Basic page structure for the app including any headers, footers, etc.
 *
 * :Author: Nik Sumikawa
 * :Date: June 21, 2020
 */

import React from 'react';

import { renderRoutes } from 'react-router-config';

import DefaultFooter from 'views/components/DefaultFooter'
import ExamplesNavbar from 'views/components/ExamplesNavbar'

export default function App({ route }){
  return (

    <div>
      <ExamplesNavbar/>
      {renderRoutes(route.routes)}
      <DefaultFooter />
    </div>

  );
};

// <ExamplesNavbar />

// const App = ({ route }) => {
//   return (
//
//     <div>
//       <div><h2>"replace with navbar or remove"</h2></div>
//       {renderRoutes(route.routes)}
//       <div><h2>"replace with footer or remove"</h2></div>
//     </div>
//
//   );
// };
//
//
// export default {
//   component: App
// };
