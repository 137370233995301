import React from "react";

// reactstrap components
import {
  NavItem,
  NavLink,
  Nav,
  TabContent,
  TabPane,
  Container,
  Row,
  Col,
  UncontrolledTooltip,
} from "reactstrap";

import { Button } from 'semantic-ui-react'

// styles for this kit
import "styles/nowui/css/bootstrap.min.css";
import "styles/nowui/scss/now-ui-kit.scss?v=1.4.0";
import "styles/nowui/demo/demo.css?v=1.4.0";
import "styles/nowui/demo/nucleo-icons-page-styles.css?v=1.4.0";

import DefaultFooter from './components/DefaultFooter'
import ExamplesNavbar from './components/ExamplesNavbar'


export function ProfilePageHeader( props ) {
  let pageHeader = React.createRef();

  React.useEffect(() => {
    if (window.innerWidth > 991) {
      const updateScroll = () => {
        let windowScrollTop = window.pageYOffset / 3;
        pageHeader.current.style.transform =
          "translate3d(0," + windowScrollTop + "px,0)";
      };
      window.addEventListener("scroll", updateScroll);
      return function cleanup() {
        window.removeEventListener("scroll", updateScroll);
      };
    }
  });


  document.body.classList.add("profile-page");
  // default profile values
  var profile = {
    backgroundImage:"url('images/background3.jpg')",
    profilePicture: "images/nik.jpg",
    username: 'Nik Sumikawa',
    category: 'Data Scientist and Web Developer',
    follows: 25,
    likes: 52,
    comments: 12,
  }

  if( props.profile !== undefined ){
    profile = props.profile
  }

  return (
    <>
      <div
        className="page-header clear-filter page-header-small"
        filter-color="blue"
      >
        <div
          className="page-header-image"
          style={{
            backgroundImage: profile.backgroundImage,
          }}
          ref={pageHeader}
        ></div>
        <Container>
          <div
            style={{height:'200px', width:'200px'}}
            className="photo-container">
            <img alt="..." src={profile.profilePicture}></img>
          </div>


          <h3 className="title">{profile.username}</h3>
          <p className="category">{profile.category}</p>

        </Container>
      </div>
    </>
  );
}

function ProfilePage( props ) {

  const [pills, setPills] = React.useState("1");
  React.useEffect(() => {
    document.body.classList.add("profile-page");
    document.body.classList.add("sidebar-collapse");
    document.documentElement.classList.remove("nav-open");
    window.scrollTo(0, 0);
    document.body.scrollTop = 0;
    return function cleanup() {
      document.body.classList.remove("profile-page");
      document.body.classList.remove("sidebar-collapse");
    };
  }, []);



  return (
    <>

      <div className="wrapper">
        <ProfilePageHeader profile={props.profile}/>

        <div className="section">
          <Container>
            <div className="button-container">

              <a href={'https://www.linkedin.com/in/nik-sumikawa-9ab30013/'} >
                <Button
                  size='massive'
                  circular
                  color='linkedin'
                  icon='linkedin'
                  />
              </a>

              <a href={'https://www.facebook.com/nsumikawa'} >
                <Button
                  size='massive'
                  circular
                  color='twitter'
                  icon='twitter'
                  />
              </a>

              <a href={'https://www.facebook.com/nsumikawa'} >
                <Button
                  size='massive'
                  circular
                  color='facebook'
                  icon='facebook'
                  />
              </a>


            </div>
            <h3 className="title">About me</h3>
            <h5 className="description">
              During the day, I am a data scientist specializing in semiconductor
              data analysis. In my free time, I enjoy building interactive websites.
              Below is a portfolio of my work.
            </h5>

            <Row>
              <Col className="ml-auto mr-auto" md="6">
                <h4 className="title text-center">My Portfolio</h4>
                <div className="nav-align-center">
                  <Nav
                    className="nav-pills-info nav-pills-just-icons"
                    pills
                    role="tablist"
                  >
                    <NavItem>
                      <NavLink
                        className={pills === "1" ? "active" : ""}
                        onClick={(e) => {
                          e.preventDefault();
                          setPills("1");
                        }}
                      >
                        <i className="now-ui-icons business_chart-bar-32"></i>
                      </NavLink>
                    </NavItem>
                    <NavItem>
                      <NavLink
                        className={pills === "2" ? "active" : ""}
                        onClick={(e) => {
                          e.preventDefault();
                          setPills("2");
                        }}
                      >
                        <i className="now-ui-icons design_image"></i>
                      </NavLink>
                    </NavItem>
                    <NavItem>
                      <NavLink
                        className={pills === "3" ? "active" : ""}
                        onClick={(e) => {
                          e.preventDefault();
                          setPills("3");
                        }}
                      >
                        <i className="now-ui-icons sport_user-run"></i>
                      </NavLink>
                    </NavItem>
                  </Nav>
                </div>
              </Col>

              <TabContent className="gallery" activeTab={"pills" + pills}>

                <TabPane tabId="pills1">
                  <Col className="ml-auto mr-auto" md="10">
                    <Row className="collections">
                      <Col md="12">

                        <h3 className="title" style={{padding:'0'}}>
                          Data Visualization Tool
                        </h3>

                        <img
                          alt="..."
                          className="img-raised"
                          src='/images/project1.gif'
                        ></img>

                        <h5 className="description"
                          style={{marginBottom:'30px'}}>
                          There are a vast number of data visualization and analytics
                          tools available in JavaScript. The one drawback that i find
                          when analyzing data is the need to set up custom analytics
                          flows when searching for correlation, splits, etc. In addition
                          it's difficult to explore the relevant spaced identified during
                          analysis. This project aims to address this problem.
                        </h5>

                        <div style={{textAlign:'center'}}>
                          <a href={'http://project1.niksumikawa.com'} >
                            <Button
                              style={{textAlign:'center'}}
                              size='large'
                              circular
                              color='twitter'
                              content='Check it out'
                              />
                          </a>
                        </div>

                      </Col>

                    </Row>
                  </Col>
                </TabPane>

                <TabPane tabId="pills2">
                  <Col className="ml-auto mr-auto" md="10">
                    <Row className="collections">
                      <Col md="12">
                        <h3 className="title" style={{padding:'0'}}>
                          Layout Editor
                        </h3>

                        <img
                          alt="..."
                          className="img-raised"
                          src='/images/project2.gif'
                        ></img>

                        <h5 className="description"
                          style={{marginBottom:'30px'}}>
                          When I first started developing webpages, I spent a lot of time
                          messing with the CSS to get the desired look. The various design
                          tools produce the CSS but it wasn't integrated into my project.
                          This tool aims to address this problem by providing a drag and
                          drop solution for various components, with the ability to customize
                          the look and feel of each component. The code used to render the
                          page can be exported into a react project so you can start developing
                          immediately.

                        </h5>

                        <div style={{textAlign:'center'}}>
                          <a href={'http://project2.niksumikawa.com'} >
                            <Button
                              style={{textAlign:'center'}}
                              size='large'
                              circular
                              color='twitter'
                              content='Check it out'
                              />
                          </a>
                        </div>

                      </Col>
                    </Row>
                  </Col>
                </TabPane>


                <TabPane tabId="pills3">
                  <Col className="ml-auto mr-auto" md="10">
                    <Row className="collections">
                      <Col md="12">
                        <h3 className="title" style={{padding:'0'}}>
                          More To Come..
                        </h3>

                        <img
                          alt="..."
                          className="img-raised"
                          src='/images/background1.jpg'
                        ></img>

                      </Col>
                    </Row>
                  </Col>
                </TabPane>
              </TabContent>
            </Row>
          </Container>
        </div>
      </div>

    </>
  );
}

export default ProfilePage;
