// import React from 'react';


// import Example from 'views/Example';
import NotFoundPage from './views/NotFoundPage';
import App from './App'
import ProfilePage from 'views/ProfilePage';




export var Routes = [
  {
    component: App,
    routes: [
      {
        // ...SsrExample,
        path: "/",
        exact: true,
        component: ProfilePage,
        ...ProfilePage,
      },

      {
        ...NotFoundPage,
      }
    ]
  },

]


// Routes[0].routes = NowuiRoutes.concat(Routes[0].routes)
// .concat( NowuiRoutes )
